import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebase/firebaseconfig";
import Swal from "sweetalert2";
import { Zoom as Fade } from "react-awesome-reveal";
import bgContainer from "../images/recruitpilot-ai-bg.png";

import Mockup from "../images/mockupextension.png";
import Star from "../images/star.png";
import Check from "../images/Check icon.png";
import Navbar from "./Navbar";
import useIsMobile from "../helper/useMobile";
import Contact from "./Contact";
import MobileSlider from "./mobile-slider";
import Footer from "./Footer";

const Extension = () => {
  const isMobile = useIsMobile();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showNavbarBackground, setShowNavbarBackground] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowNavbarBackground(true);
    } else {
      setShowNavbarBackground(false);
    }
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setUserData({});
        setIsLoggedIn(false);

        Swal.fire({
          icon: "success",
          title: "Logout Successful",
          text: "You have successfully logged out!",
        });
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const cardData = [
    {
      title: "Feedback GPT",
      description:
        "Generate fast, personalised feedback at scale straight from a job description and a CV with comparative analysis and help put and end to ghosting.",
      benefits: [
        "Feedback personalisation",
        "Time management",
        "Enhanced candidate experience",
      ],
      imgSrc: Mockup,
      reversed: false,
    },
    {
      title: "CV Summary GPT",
      description:
        "Craft targeted, consistent candidate summaries, from a CV or profile to deliver objective and detailed insights for streamlined hiring decisions.",
      benefits: [
        "Objective Insights",
        "Dynamic Summarisation",
        "Consistent Presentation",
      ],
      imgSrc: Mockup,
      reversed: true,
    },
    {
      title: "Job Ads GPT",
      description:
        "Condense lengthy job descriptions into clear, concise summaries or create job descriptions from scratch with just a few bullet points.",
      benefits: [
        "Improved Accessibility",
        "Bias Reduction",
        "Increased Consistency",
      ],
      imgSrc: Mockup,
      reversed: false,
    },
    {
      title: "Job ED&I GPT",
      description:
        "Optimize your job descriptions by identifying and eliminating biases, promoting inclusivity and attracting a more diverse and talented applicant pool.",
      benefits: [
        "Improved diversity and inclusion",
        "Mitigation of bias",
        "Higher quality applicants",
      ],
      imgSrc: Mockup,
      reversed: true,
    },
    {
      title: "Boolean Search GPT",
      description:
        "Generate precise Boolean search strings straight from job descriptions, enhancing recruiter efficiency and ensuring accurate candidate matches.",
      benefits: [
        "Efficient candidate sourcing",
        "Customised searches",
        "Targeted results",
      ],
      imgSrc: Mockup,
      reversed: false,
    },
  ];

  return (
    <div className="extension-container w-full h-full mt-20 flex flex-col justify-content space-between relative home-page-container">
      <Navbar />
      <div className="text-center w-full px-4 md:px-8 flex-grow">
        <div className="py-20 flex flex-col gap-10">
          <h1 className="text-4xl sm:text-6xl font-semibold leading-loose">
            Welcome to
            <span className="text--primary block sm:mt-4">
              RecruitPilot GPTs
            </span>
          </h1>
          <div className="text--primary font-semibold text-sm sm:text-xl">
            Slash recruitment admin and save more time for candidate interaction
            with our free GPTs
          </div>
          <a
            href="/try-gpt"
            className="block cursor-pointer button--gpt button--primary w-[240px] py-3 px-6t text-lg self-center z-100"
          >
            Try GPTs free
          </a>
        </div>
      </div>

      <div className="w-full flex flex-col items-center gap-2 px-4 md:px-8">
        {/* <img className="background-pattern" src="/Border.png" /> */}
        {isMobile ? (
          <MobileSlider />
        ) : (
          <div className="flex sm:flex-wrap justify-center gap-10 w-full">
            {[1, 2, 3, 4, 5, 6, 7].map((ico) => (
              <img src={`/extension/ext-${ico}.svg`} alt="icon1" />
            ))}
          </div>
        )}

        <div className="text-center mt-[90px] w-full md:mt-12 flex flex-col gap-8">
          <h2 className="text-3xl leading-loose sm:text-4xl font-semibold text--primary">
            Our GPT features and benefits
          </h2>
          <p className="text-sm sm:text-2xl">
            Create job board ready ads and boolean search or summarise job
            descriptions and CV's in seconds.
          </p>
        </div>

        <div className="my-12 items-center ">
          {cardData.map((card, index) => (
            <Fade>
              <div
                key={index}
                className={`rounded-xl sm:rounded-[50px] justify-center items-center flex flex-col md:flex-row bg-[#1E1E1E] sm:px-24 md:mx-20 p-6 my-24 sm:my-16  ${card.reversed ? "md:flex-row-reverse" : ""
                  } gap-6`}
              >
                <div className="flex-1 text-white">
                  <div className="flex items-center mb-4">
                    <h3 className="text-3xl font-bold mr-2">{card.title}</h3>
                    <img
                      src={Star}
                      alt="star"
                      className="w-[30px] h-[30px] md:w-[50px] md:h-[50px]"
                    />
                  </div>
                  <p className="mb-5 font-normal text-lg leading-7">
                    {card.description.split("\n").map((line, i) => (
                      <p key={i} className="block">
                        {line}
                      </p>
                    ))}
                  </p>
                  <ul className="list-none">
                    {card.benefits.map((benefit, i) => (
                      <li key={i} className="flex items-center mb-4">
                        <img
                          src={Check}
                          alt="check"
                          className="w-[20px] h-[20px] md:w-[28px] md:h-[28px] mr-2"
                        />
                        <p className="text-lg font-normal">{benefit}</p>
                      </li>
                    ))}
                    <a
                      href={'/signup'}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button--gpt bg-[#ff9900] w-[200px] mt-8 text-white py-2 px-6 rounded flex items-center justify-center "
                    >
                      {'Try GPT for free'}{" "}
                      <svg
                        className="w-6 h-6 ml-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 5l7 7-7 7"
                        ></path>
                      </svg>
                    </a>
                  </ul>
                </div>
                <div className="flex-1 flex justify-center items-center">
                  <img
                    src={
                      index == 1
                        ? `/list/list-2.png`
                        : `/list/list-${index + 1}.svg`
                    }
                    alt={card.title}
                    className="w-[300px] h-[200px] md:w-[582px] md:h-[352px]"
                  />
                </div>

              </div>
            </Fade>
          ))}
        </div>
      </div>

      <div className="sm:mt-12">
        {/* Content after the cards */}
        <Contact isFooter={false} />
      </div>

      <div className="sm:mt-60">
        <Footer />
      </div>

      <div className="absolute w-full top-[350px] sm:top-[200px] h-[300px] sm:h-[1900px] bg-logo"></div>
      <div className="absolute w-full top-[2000px] h-[300px] sm:h-[1700px] bg-logo"></div>
    </div>
  );
};

export default Extension;
