import React, { useState } from "react";
import { Link } from "react-router-dom";

const MobileDropDown = ({ userName, onSignoutClick, isLoggedIn }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className="inline-flex justify-between items-center px-4 py-2 font-medium rounded-md bg-transparent"
      >
        <img src="/menu-burger.svg" alt="Menu" />
      </button>

      {dropdownOpen && (
        <>
          {isLoggedIn ? (
            <div className="absolute right-0 mt-2 w-56 bg-[#171717] border-[#3A3A3A] rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="py-1 flex flex-col">
                
                <Link
                  to="/try-gpt"
                  className="block px-4 py-2 text-sm text-white hover:bg-[#3A3A3A] text-center"
                >
                  Try GPTs
                </Link>
                <button
                  onClick={onSignoutClick}
                  className="flex items-center justify-center w-full px-4 py-2 text-sm text-white hover:bg-[#3A3A3A]"
                >
                  <img src="/logout.svg" alt="Logout Icon" className="mr-2" />
                  Log Out
                </button>
              </div>
            </div>
          ) : (
            <div className="absolute right-0 mt-2 w-64 bg-[#171717] border-[#3A3A3A] rounded-md shadow-lg ring-1 ring-black ring-opacity-5 flex flex-col p-5">
              <Link
                to="/login"
                className="login-button m-2 button--secondary text-[18px] font-semibold text-center"
              >
                Log in
              </Link>
              <Link to="/extension">
                <button className="button--gpt w-[195px] py-3 px-6 m-2">
                  <p className="text-[18px] font-semibold text-center">
                    Try our GPTs
                  </p>
                </button>
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MobileDropDown;
