import React, { useEffect } from 'react'
import data from '../data/privacy-policy.json'
import Navbar from './Navbar'
import Footer from './Footer'

const Policy = () => {

    useEffect(()=>{
        window.scrollTo({
            behavior:'smooth',
            top:0
        })
    },[])

    return (
        <div>
            <Navbar />
            <div className='mt-32 sm:w-[1200px] m-auto'>

              <p className='text-center m-5'>Version 1.3 – Last Updated: 06/08/2024</p>
              <h1 className='sm:text-6xl text-2xl text-center m-4'>RecruitPilot AI Privacy Notice</h1>

              <p className='text-center m-4 text-md'>
              Welcome to RecruitPilot AI! This Privacy Notice outlines how we collect, use, and protect your personal information when you interact with our products and services, including Custom GPTs available in the GPT store, our Chrome extension, and our Web app. By using our services, you consent to the collection and use of your information as described in this Privacy Notice.
              </p>
            {
                data.privacy_notice.map((item,index) => (
                    <dl className='p-4 sm:w-[1200px] m-auto'>
                        <dt className='text-[#FF9900] font-bold'>{index+1}. {item.title}</dt>
                        {
                            item.points.map((sub)=>(
                                <dd className='text-justify'>- {sub.content}</dd>
                            ))
                        }
                    </dl>
                ))
            }
            </div>
            <Footer/>
        </div>
    )
}

export default Policy