import React, { useEffect } from "react";
import ScrollReveal from "scrollreveal";
import avatars from "../images/avatars.png";

const Review = () => {
  useEffect(() => {
    // Initialize ScrollReveal for the main container
    ScrollReveal().reveal(".container-inner", {
      duration: 1000,
      origin: "bottom",
      distance: "50px",
      easing: "ease-in-out",
      reset: true,
    });

    // Initialize ScrollReveal for each link container item
    ScrollReveal().reveal(".container-links img", {
      duration: 1000,
      origin: "left",
      distance: "50px",
      easing: "ease-in-out",
      delay: 300,
      reset: true,
    });

    ScrollReveal().reveal(".container-links p", {
      duration: 1000,
      origin: "right",
      distance: "50px",
      easing: "ease-in-out",
      delay: 300,
      reset: true,
    });
  }, []);

  return (
    <section className="flex flex-col items-center text-center mt-12 py-16 px-4 rounded-xl sm:px-24 sm:rounded-[50px] bg-[#1E1E1E] w-full">
      {/* <img src={avatars} alt="contact" /> */}
      <div className="text-2xl font-semibold mt-5">
        <p>
          <span>Partners</span>
        </p>
      </div>

      <div className="flex mt-5 sm:flex-row flex-col">
        <div className="sm:ml-12 mt-5">
          <img src="micro-soft.png" />
        </div>
        <div className="sm:ml-12 mt-5">
          <img src="aws.png"/>

        </div>

      </div>
    </section>
  );
};

export default Review;
