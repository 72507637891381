import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth } from "../firebase/firebaseconfig";
import CustomPopup from "./CustomPopup";
import logo from "../images/logo.png";
import googleLogo from "../images/Google.png";
import Footer from "./Footer";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        navigate("/");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (popup) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [popup]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      setPopup({
        type: "success",
        message: "You have successfully logged in to RecruitPilot.AI.",
      });
      navigate("/try-gpt");
    } catch (error) {
      setPopup({
        type: "error",
        message: "Looks like you couldn’t log in there. Please try again.",
      });
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate("/try-gpt");
    } catch (error) {
      setError(error.message);
    }
  };

  const closePopup = () => {
    setPopup(null);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-[#0e0e0e] text-white px-4">
      <img
        onClick={() => navigate("/")}
        className="h-16 w-16 cursor-pointer mt-24 sm:mt-0"
        src={logo}
        alt="logo"
      />

      <div className="text-center mb-6">
        <h2 className="text-3xl font-semibold">Log in to your account</h2>
        <p className="mt-2 text-base font-normal">
          Welcome! Please enter your details.
        </p>
      </div>
      {popup && (
        <CustomPopup
          type={popup.type}
          message={popup.message}
          onClose={closePopup}
        />
      )}
      <div className="bg-[#1E1E1E] p-4 sm:p-10 rounded-[20px] shadow-lg w-full sm:max-w-md">
        <div className="bg-[#1E1E1E] p-4 sm:p-10 rounded-lg shadow-lg w-full sm:max-w-md">
          <form onSubmit={handleLogin} className="space-y-4">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-white"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                className="w-full p-2 mt-1 bg-[#2B2C2E] text-white border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-[#FF9900]"
                placeholder="Enter your Email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-white"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                className="w-full p-2 mt-1 bg-[#2B2C2E] text-white border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-[#FF9900]"
                placeholder="Enter your Password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex justify-between">
              <Link to="/forgot-password" className="text-sm text-[#FF9900]">
                Forgot Password?
              </Link>
            </div>
            <button
              type="submit"
              className="w-full p-2 mt-4 bg-transparent border border-[#FF9900] rounded button--gpt text-white text-lg"
              disabled={loading}
            >
              {loading ? (
                <div className="flex justify-center ">
                  <div className="w-5 h-5 border-t-2 border-white rounded-full animate-spin"></div>
                </div>
              ) : (
                "Continue"
              )}
            </button>

            <button
              type="button"
              onClick={() => navigate("/")}
              className="w-full py-2 mt-5 text-lg login-button button--secondary"
            
            >
              Back
            </button>
            {/* {error && <p className="text-center mt-4 text-red-500">{error}</p>} */}
          </form>
          <p className="text-center mt-4">
            Don't have an account?{" "}
            <Link to="/signup" className="text-[#FF9900]">
              Sign up
            </Link>
          </p>

          <div className="flex items-center my-4">
            <div className="flex-grow border-t border-gray-400"></div>
            <span className="mx-4 text-sm">OR</span>
            <div className="flex-grow border-t border-gray-400"></div>
          </div>
          <button
            onClick={handleGoogleLogin}
            className="w-full p-2 bg-[#2B2C2E] text-white rounded flex items-center justify-center text-lg"
          >
            <img src={googleLogo} alt="Google logo" className="w-5 h-5 mr-2" />
            Continue with Google
          </button>
        </div>

        {/* <Footer /> */}
      </div>
    </div>
  );
}

export default LoginPage;
