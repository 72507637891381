import React, { useState, useEffect } from "react";
import "./style.css";

const TypingAnimation = () => {
  const [text, setText] = useState("");
  const fullText = "Stay Human";
  const typingSpeed = 200; // Speed of typing in milliseconds

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      setText(fullText.substring(0, index + 1));
      index++;
      if (index === fullText.length) {
        clearInterval(interval);
      }
    }, typingSpeed);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="typing-container text-xl sm:text-4xl">
      <p style={{ fontWeight: "600", fontFamily: "Urbanist" }}>
        {text}
        <span className="cursor">|</span>
      </p>
    </div>
  );
};

export default TypingAnimation;
