import React, { useEffect, useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import './style.css'



const animation = { duration: 40000, easing: (t) => t };

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check initial screen size
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
};

const FeatureCarousel = () => {


  const isMobile = useIsMobile();
  const [sliderRef] = useKeenSlider({
    loop: true,
    renderMode: "performance",
    drag: true,
    
    slides: {
      perView: 2,
      spacing: 15,
    },
    created(s) {
      s.moveToIdx(5, true, animation);
    },
    updated(s) {
      s.moveToIdx(s.track.details?.abs + 5, true, animation);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details?.abs + 5, true, animation);
    },
  });

  return (
    <div className="relative overflow-hidden w-full mt-11">
     <div ref={sliderRef} className="keen-slider">
      {
        [1,2,3,4,5,6,7,8].map((item,index)=>(
          <div className="keen-slider__slide">
             <img
                style={{
                  width:'100%',
                  height:'auto',
                  objectFit:'conver',
                  
                }}
                src={
                  isMobile
                    ? `mobile/mobile-${index + 1}.svg`
                    : `slides/slide${index + 1}.png`
                }
                // className="w-full"
                alt={`Slide ${index + 1}`}
              />
          </div>
        ))
      }
    </div>
      {/* <Slider {...settings}>
        {[...Array(slideCount * 3)].map((_, index) => (
          <div key={index} className="m-2">
            <div className="section--card flex relative">
              <img
                style={{
                  width:'100%',
                  height:'auto',
                  objectFit:'conver',
                  
                }}
                src={
                  isMobile
                    ? `mobile/mobile-${(index % slideCount) + 1}.svg`
                    : `slides/slide${(index % slideCount) + 1}.png`
                }
                // className="w-full"
                alt={`Slide ${index + 1}`}
              />
            </div>
          </div>
        ))}
      </Slider> */}
    </div>
  );
};

export default FeatureCarousel;
