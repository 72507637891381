import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase/firebaseconfig';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import CustomPopup from './CustomPopup';

function VerificationPage() {
  const [isVerified, setIsVerified] = useState(false);
  const [popup, setPopup] = useState(null);
  
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const checkVerification = setInterval(() => {
          user.reload().then(() => {
            if (user.emailVerified) {
              setIsVerified(true);
              setPopup({
                type:'verif_success',
                message:'Congratulations! Your email is verified, and you are now logged in.'
              })
              // Swal.fire({
              //   icon: 'success',
              //   title: 'Email Verified',
              //   text: 'Your email has been successfully verified.',
              // }).then(() => {
              //   navigate('/');
              // });
              clearInterval(checkVerification);
            }
          });
        }, 3000); // Check every 3 seconds

        return () => clearInterval(checkVerification);
      }
    });

    // Prevent back navigation
    const handleBackButton = (event) => {
      event.preventDefault();
      window.history.pushState(null, null, window.location.pathname);
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
      unsubscribe();
    };
  }, [navigate]);

  
  const closePopup = () => {
    setPopup(null);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#0e0e0e]">
      {popup && <CustomPopup type={popup.type} message={popup.message} onClose={closePopup} />}

      <div className="bg-[#1e1e1e] p-8 rounded-lg shadow-lg text-center">
        <h2 className="text-3xl font-semibold text-white mb-4">Email Verification</h2>
        <p className="text-lg text-gray-300 mb-6">
          Please verify your email by clicking the link sent to your email address. This page will automatically update once your email is verified.
        </p>
        {!isVerified && <p className="text-lg text-yellow-500">Waiting for verification...</p>}
      </div>
    </div>
  );
}

export default VerificationPage;
