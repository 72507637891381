import React, { useEffect } from "react";
import ScrollReveal from "scrollreveal";
import Group from "../images/div.Cu2ColumnIconBox_mainIcon__VJrT6.png";
import SVG from "../images/SVG.png";
import Review from "./Review";
import Waitlist from "./Waitlist";
import Footer from "./Footer";

const Contact = ({ isEnable = true, isFooter = false }) => {
  useEffect(() => {
    // Initialize ScrollReveal for the main container
    ScrollReveal().reveal(".container-inner", {
      duration: 1000,
      origin: "bottom",
      distance: "50px",
      easing: "ease-in-out",
      reset: true,
    });

    // Initialize ScrollReveal for each link container item
    ScrollReveal().reveal(".container-links img", {
      duration: 1000,
      origin: "left",
      distance: "50px",
      easing: "ease-in-out",
      delay: 300,
      reset: true,
    });

    ScrollReveal().reveal(".container-links p", {
      duration: 1000,
      origin: "right",
      distance: "50px",
      easing: "ease-in-out",
      delay: 300,
      reset: true,
    });
  }, []);

  return (
    <>
      {!isEnable && (
        <section className="section--panel flex flex-col items-center">
          <img src={Group} alt="contact" />
          <div className="font-bold text-[#8899EE] text-md leading-4 uppercase">
            #1 Support in software
          </div>
          <div className="font-bolder text-6xl tracking-wide mt-6 mb-12 text-center sm:text-left font-normal">
            <span className="inline-block text--primary ">24/7</span> real-time
            support.
          </div>
          <div className="mb-8 text-md">
            Experience peace of mind with our 24/7 real-time support, ensuring
            that assistance is just a message or call away, <br/>
            <p className="text-center">
            Any time, day or
            night.
              </p> 
          </div>
          <div className="flex flex-col gap-5 pb-12 sm:flex-row">
            {[
              "Email Support",
              "Live chat for web app users",
              "Frequent webinars",
            ].map((feature, key) => (
              <div className="flex flex-row gap-2 items-center" key={key}>
                <img src={SVG} alt="SVG" />
                <span className="text-white font-[500] text-sm">{feature}</span>
              </div>
            ))}
          </div>
        </section>
      )}
      <div className="flex flex-col gap-6 mt-12">
        <p className="font-medium	text-center text-2xl text--lexend sm:text-6xl">
          We are in a <span className="text--primary">new era</span> of
          hiring
        </p>
        <p style={{fontFamily:'Lexend'}} className="text-center m-3 font-normal text-lg sm:text-xl ">
          Be at the head of the queue to hear about our up and coming product
          releases
        </p>
        <Waitlist />
      </div>

      {isFooter && <Footer />}
    </>
  );
};

export default Contact;
