import { useState } from "react";
import { firestore } from "../firebase/firebaseconfig"; // Adjust the import path as necessary
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";

function Waitlist() {
  const [email, setEmail] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [message, setMessage] = useState("");

  async function handleButtonClick() {
    if (!email) {
      setMessage("Please enter an email.");
      return;
    }

    try {
      const q = query(
        collection(firestore, "waitlist"),
        where("email", "==", email)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setMessage("This email is already on the waitlist.");
        return;
      }

      await addDoc(collection(firestore, "waitlist"), {
        email: email,
        timestamp: new Date(),
      });

      setMessage("Email added to the waitlist successfully!");
      setEmail("");
    } catch (e) {
      setMessage("Error adding email to the waitlist.");
      console.error("Error adding document: ", e);
    }
  }

  const re = /\S+@\S+\.\S+/;
  return (
    <div className="w-full flex flex-col justify-center sm:flex-row items-center gap-4 text-md px-4">
      <input
        className={`text-lg w-auto min-w-[300px] ${
          isFocused ? "focused-input" : null
        }`}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button
        style={{ color: "#FF9900" }}
        className={`button--secondary ${
          re.test(email) ? "button--gpt" : null
        } w-full sm:w-[190px]`}
        onClick={handleButtonClick}
      >
        Join Mailing List
      </button>
      {message && <p style={{ color: "red" }}>{message}</p>}
    </div>
  );
}

export default Waitlist;
