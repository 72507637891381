import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'accepted');
        setIsVisible(false);
    };

    const handleReject = () => {
        localStorage.setItem('cookieConsent', 'rejected');
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="cookie-consent">
            <div className='flex sm:flex-row flex-col justify-evenly sm:w-full'>
            <p className='m-2 sm:w-[900px] text-justify'>We use cookies and similar technology to enhance, maintain and deliver our services as well as for security purposes. Check our Privacy Policy for details to learn about the cookies we use and how we process data. Click ‘Accept all’ to allow RecruitPilot AI and partners use for these purposes. Click ‘Reject all’ to say no to cookies, except those that are strictly necessary.</p>
            <div className='flex items-center'>
                <button className='button--outline w-36 rounded-lg' onClick={handleAccept}>Accept</button>
                <button className='button--outline w-36 rounded-lg' onClick={handleReject}>Reject</button>
            </div>

            </div>

        </div>
    );
};

export default CookieConsent;
