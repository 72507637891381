import React from "react";
import heroimg from "../images/article-svg.svg";
const RecruitPilot = () => {
  return (
    <div className="flex flex-col lg:flex-row bg-[#1E1E1E] text-white items-center mx-auto py-10 px-4 lg:px-12 lg:py-2 lg:gap-12 sm:rounded-[50px] rounded-xl gap-4">
      <div className="flex-shrink-0 mb-6">
        <img className="" src={heroimg} />
      </div>
      <div className="flex flex-col gap-4 text-xl sm:text-2xl sm:leading-7 font-normal">
        <p className="">
          We all know the recruitment world is being transformed by AI: it
          handles the heavy lifting, enabling hiring managers and recruiters to
          maximise their productivity.
        </p>
        <p className="text-[#FF9900] my-6">
          But we think that’s just a table stake.
        </p>
        <p className="">
          ‘RecruitPilot AI’ creates that ‘time’ but crucially enables,
          encourages & guides its users to focus more of that time on ensuring
          that job seekers get the experience they deserve, whoever they are and
          wherever they are across the globe.
        </p>
      </div>
    </div>
  );
};

export default RecruitPilot;
