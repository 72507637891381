import React, { useMemo, useState } from "react";
// import videoSource from "../video/animated_logo_video.webm";
import videoSource from "../video/home-animation.webm";
import videoSourceSafari from "../video/logo.mov";
import animated from "../video/final-solution.png";
import TypingAnimation from "./TypingAnimation";
import { useEffect } from "react";

function supportsHEVCAlpha() {
  const navigator = window.navigator;
  const ua = navigator.userAgent.toLowerCase();
  const hasMediaCapabilities = !!(
    navigator.mediaCapabilities && navigator.mediaCapabilities.decodingInfo
  );
  const isSafari =
    ua.indexOf("safari") !== -1 &&
    !(ua.indexOf("chrome") !== -1) &&
    ua.indexOf("version/") !== -1;
  return isSafari && hasMediaCapabilities;
}

const Hero = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true);
    }, 4000);
  }, []);

  const isSafari = useMemo(() => supportsHEVCAlpha(), []);
  // alert(JSON.stringify(isSafari));

  return (
    <div className="flex flex-col items-center gap-4 pb-0 pt-10">
      <div className="h-16 w-full mt-12 sm:h-60 bg-transparent">
        {/** Animated Logo */}
        {
          isSafari ?
          <img className="sm:m-20 w-full" src={animated} />
          :
        <video

          style={{ background: "transparent" }}
          // className="h-full"
          // className="absolute left-0 w-full sm:-top-[120px]"
          src={isSafari ? videoSourceSafari : videoSource}
          autoPlay
          muted
          playsInline
        />
        }
      </div>

      <div className="sm:pt-10 sm:min-h-32 min-h-8">
        {isLoading && <TypingAnimation />}
      </div>

      <div className="max-h-[527px] max-w-[928px] rounded-xl overflow-hidden ">
        <img className="h-full w-full mt-5" src={'/hero-image.png'} alt="robot" />
      </div>
    </div>
  );
};

export default Hero;
