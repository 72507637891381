import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, sendPasswordResetEmail, onAuthStateChanged, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../firebase/firebaseconfig';
import CustomPopup from './CustomPopup';
import logo from '../images/logo.png';
import googleLogo from '../images/Google.png';
import Footer from './Footer';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (popup) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [popup]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
       await sendPasswordResetEmail(auth, email);
    
        setPopup({
            type:'forgot_password',
            message:'Please check your email'
        })
    } catch (error) {
   
      setPopup({
        type: 'error',
        message: 'Looks like you couldn’t log in there. Please try again.',
      });
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Password reset email sent. Please check your inbox.');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate('/try-gpt');
    } catch (error) {
      setError(error.message);
    }
  };

  const closePopup = () => {
    setPopup(null);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-[#0e0e0e] text-white">
      <img onClick={() => navigate('/')} className="h-16 w-16 cursor-pointer" src={logo} alt="logo" />

      <div className="text-center mb-6">
        <h2 className="text-3xl font-semibold">Forgot your password?</h2>
        <p className="mt-2 text-base font-normal">Enter email we will send you reset password link.</p>
      </div>
      {popup && <CustomPopup type={popup.type} message={popup.message} onClose={closePopup} />}
      <div className="bg-[#1E1E1E] p-10 rounded-lg shadow-lg w-full max-w-md">
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-white">Email</label>
            <input
              id="email"
              type="email"
              className="w-full p-2 mt-1 bg-[#2B2C2E] text-white border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-[#FF9900]"
              placeholder="Enter your Email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
     
       
          <button
            type="submit"
            className="w-full p-2 mt-4 bg-transparent border border-[#FF9900] rounded button--gpt text-white"
            disabled={loading}
          >
            {loading ? (
              <div className="flex justify-center ">
                <div className="w-5 h-5 border-t-2 border-white rounded-full animate-spin"></div>
              </div>
            ) : (
              'Continue'
            )}
          </button>
          <button type="button" onClick={()=>navigate('/login')} className="w-full py-2 mt-5" style={{  color: '#FF9900', borderRadius: '0.375rem', outline: 'none', border: '1px solid #FF9900' }}>
              Back
            </button>
          {/* {error && <p className="text-center mt-4 text-red-500">{error}</p>} */}
        </form>
      </div>

      {/* <Footer /> */}
    </div>
  );
}

export default ForgotPassword;
