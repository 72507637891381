import React from "react";
import { Link } from "react-router-dom";
import rpalogo from "../images/logos/logo_dark_mode.png";

const Footer = () => {
  return (
  <footer className="mt-16 bg-[#1E1E1E] w-full px-6 sm:px-24 py-12 text-center">
      <div style={{ display:'flex',alignItems:'center',justifyContent:'center' }}>
        <img src={rpalogo} alt="rpa" />
      </div>
      <div className="footer-line my-6" />

      <div className="flex flex-col-reverse mt-3 sm:flex-row justify-center">
      

        <div className="flex sm:flex-row flex-row gap-5 m-auto sm:m-0">
          <Link  to="/terms">
            <span  className=" text-hover text--secondary text-[#FF9900] font-normal">
              Terms
            </span>
          </Link>
          <Link to="/policy">
            <span className="text--secondary text-hover text-[#D0D5DD]">Privacy</span>
          </Link>
          {/* <Link to="#">
            <span className="text--secondary text-[#D0D5DD]">Cookies</span>
          </Link> */}
        </div>
      </div>
      <div className="text--secondary text-[#D0D5DD]">
          © {new Date().getFullYear().toString()} RecruitPilot Ai. All rights
          reserved.
        </div>
    </footer>
  );
};

export default Footer;
