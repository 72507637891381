import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../firebase/firebaseconfig";
import logo from "../images/logo.png";
import CustomPopup from "./CustomPopup";
import DropdownButton from "./profile-dropdown";
import { doc, getDoc } from "firebase/firestore";
import LogoutPopup from "./logout-popup";
import useIsMobile from "../helper/useMobile";
import MobileDropDown from "./mobile-dropdown";
import VerifyPopup from "./VerifyPopup";

function Navbar() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [popup, setPopup] = useState(null);
  const [logoutPopup, setlogoutPopup] = useState(false);
  const [showNavbarBackground, setShowNavbarBackground] = useState(false); // Added state
  const [user, setUser] = useState(null);

  let location = useLocation();
  console.log(location.pathname);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        setIsLoggedIn(true);

        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserName(userData.username || user.displayName || "");
        } else {
          setUserName(user.displayName || "");
        }

        if (!sessionStorage.getItem("loginAlertShown")) {
          setPopup({
            type: "success",
            message: "You have successfully logged in!",
          });
          sessionStorage.setItem("loginAlertShown", "true");
        }
      } else {
        setIsLoggedIn(false);
        setUserName("");
        sessionStorage.removeItem("loginAlertShown");
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowNavbarBackground(true);
    } else {
      setShowNavbarBackground(false);
    }
  };

  const closePopup = () => {
    setPopup(null);
  };

  console.log("debug => user", user);

  return (
    <div
      className={`navbar p-2  max-w-[1300px] mx-auto px-4 md:px-8 flex flex-row justify-between mt-4 h-[70px] py-2 transition-all duration-500 ${
        showNavbarBackground ? "nav-bar shadow-lg scale-up" : ""
      }`}
    >
      {popup && (
        <CustomPopup
          type={popup.type}
          message={popup.message}
          onClose={closePopup}
        />
      )}
      {logoutPopup && <LogoutPopup onClose={() => setlogoutPopup(false)} />}
      {user && !user.emailVerified && (
        <VerifyPopup
          type="error"
          headerText="Email Not Verified."
          messageText="Looks like your email is not verified. Please try again."
          showResetLink={true}
        />
      )}

      <img
        onClick={() => navigate("/")}
        className="h-full cursor-pointer"
        src={logo}
        alt="logo"
      />

      {isMobile ? (
        <div>
          <MobileDropDown
            isLoggedIn={isLoggedIn}
            onSignoutClick={() => setlogoutPopup(true)}
          />
        </div>
      ) : (
        <div className="hidden sm:flex sm:flex-row gap-6 items-center">
          {isLoggedIn ? (
            <DropdownButton
              onSignoutClick={() => setlogoutPopup(true)}
              userName={userName}
            />
          ) : (
            <>
              <Link
                to="/login"
                className="login-button button--secondary text-[18px] font-semibold"
              >
                Log in
              </Link>
              {location.pathname.startsWith("/extension") ? (
                <Link to="/signup">
                  <button className="button--gpt py-3 px-6">
                    <p className="font[18px] font-semibold">Sign Up</p>
                  </button>
                </Link>
              ) : (
                <Link to="/extension">
                  <button className="button--gpt w-[195px] py-3 px-6">
                    <p className="font[18px] font-semibold">Try our GPTs</p>
                

                  </button>
                </Link>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Navbar;
