import React, { useEffect } from 'react'
import data from '../data/terms-services.json'
import Navbar from './Navbar'
import Footer from './Footer'

const Terms = () => {

    useEffect(() => {
        window.scrollTo({
            behavior: 'smooth',
            top: 0
        })
    }, [])

    return (
        <div>
            <Navbar />
            <div className='mt-32 sm:w-[1200px] m-auto'>

            <p className='text-center m-5'>Version 1.1 – Last Updated: 08/08/2024</p>

                <h1 className='sm:text-6xl text-2xl text-center m-4'>RecruitPilot AI Terms of Service</h1>

                <p className='text-center m-4 text-md'>Welcome to RecruitPilot AI! These Terms of Service ("Terms") govern your use of our products and services, including Custom GPTs available in the GPT store, our Chrome extension and our Web app. By accessing or using our services, you agree to be bound by these Terms.
                </p>
                {
                    data.terms_and_conditions.map((item, index) => (
                        <dl className='p-4 sm:w-[1200px] m-auto'>
                            <dt className='text-[#FF9900] font-bold'>{index + 1}. {item.title}</dt>
                            {
                                item.points.map((sub) => (
                                    <dd className='text-justify'>- {sub.content}</dd>
                                ))
                            }
                        </dl>
                    ))
                }
            </div>
            <Footer />
        </div>
    )
}

export default Terms