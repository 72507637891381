import React, { useState } from "react";
import { Link } from "react-router-dom";

const DropdownButton = ({ userName, onSignoutClick }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className="inline-flex justify-between items-center px-4 py-2 border border-[#FF9900] text-[#FF9900] font-medium rounded-md bg-transparent hover:bg-[#FF9900] hover:text-white"
      >
        {userName ? userName : ''}
        <img className="h-[15px] m-3" src='/user-avatar.svg' alt="User Avatar" />
        <img src='/arrow-up.svg' alt="Arrow" />
      </button>

      {dropdownOpen && (
        <div className="absolute right-0 mt-2 w-56 bg-[#171717] border-[#3A3A3A] rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            <Link
              to="/try-gpt"
              className="block px-4 py-2 text-sm text-white m-2 hover:bg-[#3A3A3A] border-[#3A3A3A] rounded-md border-[1px]"
            >
              Try GPTs
            </Link>
            <button
              onClick={onSignoutClick}
              className="flex w-52 px-4 py-2 text-sm text-white m-2 hover:bg-[#3A3A3A] border-[#3A3A3A] rounded-md border-[1px]"

            >
              <img src="/logout.svg" alt="Logout Icon" className="mr-2" />
              Log Out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownButton;
