// Homepage.js
import { Zoom as Fade } from "react-awesome-reveal";
import Hero from "./components/Hero";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import FeatureCarousel from "./components/Carousel";
// images
import Review from "./components/Review";
import RecruitPilot from "./components/HeroCardOne";
import RecruitPilotTwo from "./components/HeroCardTwo";

function Homepage() {
  return (
    <div className="w-full h-full flex flex-col items-center relative home-page-container">
      {/* Homepage navbar */}
      <Navbar />

      {/* Homepage contents */}
      <div className="sm:flex sm:flex-col sm:items-center sm:content-center flex-grow w-full mx-auto">
        <div className="max-w-[1400px] pb-24 mx-2">
          <Fade>
            <Hero />
          </Fade>

          <Fade>
            <div className="text-center mt-16 sm:mt-20 mb-20 text-xs px-4 md:px-8">
              <div className="text-4xl font-semibold text-center my-6 tex--lexend sm:text-7xl">
                A <span className="">recruitment revolution</span> that <br />{" "}
                serves everyone
              </div>
            </div>
          </Fade>

          <div className="flex flex-col gap-12 items-center min-h-[700px] px-4 md:px-8">
            <Fade>
              <RecruitPilot />
            </Fade>
            <div className="w-full">
              <FeatureCarousel />
            </div>
            <Fade>
              <p className="text-4xl font-semibold text-center my-6">
                Bringing <span>humanity forward</span> to automation in HRTech
              </p>
            </Fade>
            <Fade>
              <RecruitPilotTwo />
            </Fade>
            <Fade className="w-full">
              <Review />
            </Fade>
            <Fade className="w-full">
              <Contact isEnable={false} />
            </Fade>
          </div>
        </div>

        <Footer />
      </div>

      <div className="absolute w-full top-[200px] h-[300px] sm:h-[1900px] bg-logo"></div>
      <div className="absolute w-full top-[600px] sm:top-[2000px] h-[300px] sm:h-[1700px] bg-logo"></div>
    </div>
  );
}

export default Homepage;
