import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// pages
import Homepage from "./HomePage";
import LoginPage from "./components/LoginPage";
import Policy from "./components/Policy";
import Extension from "./components/Extension";
import SignUpPage from "./components/Signup";
import VerificationPage from "./components/VerificationPage";
// stylesheets
import "./App.css";
import "./Responsive.css";
import TryGptPage from "./components/TrygptPage";
import PrivateRoute from "./routes/PrivateRoute";
import CustomPopup from "./components/CustomPopup";
import Terms from "./components/Terms";
import CookieConsent from "./components/CookieConsent";
import { getAnalytics, isSupported } from "firebase/analytics";
import { app } from "./firebase/firebaseconfig";
import ForgotPassword from "./components/forgot-password";

const App = () => {
  const initializeAnalytics = async () => {
    const consent = localStorage.getItem("cookieConsent");
    if (consent === "accepted") {
      const analyticsSupported = await isSupported();
      if (analyticsSupported) {
        getAnalytics(app);
      }
    }
  };

  useEffect(() => {
    initializeAnalytics();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/extension" element={<Extension />} />
        <Route path="/verify-email" element={<VerificationPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route element={<CustomPopup />} />
        <Route
          path="/try-gpt"
          element={
            <PrivateRoute>
              <TryGptPage />
            </PrivateRoute>
          }
        />
      </Routes>
      <CookieConsent />
    </Router>
  );
};

export default App;
