import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase/firebaseconfig";
import { doc, setDoc } from "firebase/firestore";
import logo from "../images/logo.png";
import VerifyPopup from "./VerifyPopup";

function SignUpPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [error, setError] = useState(null);
  const [showEmailVerificationPopup, setShowEmailVerificationPopup] =
    useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== repeatPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      setLoading(true);

      const result = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const { user } = result;

      const userData = { username, email };
      const userDocRef = doc(db, "users", user.uid);
      await setDoc(userDocRef, userData);
      setShowEmailVerificationPopup(true);
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  return (
    <div
      className="flex flex-col items-center justify-center py-12 px-4"
      style={{ backgroundColor: "#0e0e0e" }}
    >
      {showEmailVerificationPopup && (
        <VerifyPopup showResetLink={true} triggerSend={true} />
      )}

      <div className="flex justify-center mb-4">
        <img src={logo} alt="Logo" className="h-16 w-16" />
      </div>
      <h2
        className="text-center sm:text-3xl text-2xl font-semibold mb-6"
        style={{ color: "#D9D9D9" }}
      >
        Sign up now and receive access <br /> to our free GPTs
      </h2>
      <div className="bg-[#1E1E1E] p-4 sm:p-10 rounded-lg shadow-lg w-full sm:max-w-md">
        <form className="space-y-4" onSubmit={handleSignUp}>
          <div>
            <label
              className="block font-normal text-xl mb-2"
              style={{ color: "#D9D9D9" }}
            >
              Name
            </label>
            <input
              type="text"
              placeholder="Enter your name"
              className="w-full p-2 mt-1 bg-[#2B2C2E] text-white border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-[#FF9900]"
              style={{
                backgroundColor: "#2B2C2E",
                color: "#D9D9D9",
                borderRadius: "0.375rem",
                outline: "none",
                border: "none",
                padding: "13px",
                marginBottom: "10px",
              }}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />

            <label
              className="block font-normal text-xl mb-2"
              style={{ color: "#D9D9D9" }}
            >
              Your Email
            </label>
            <input
              type="email"
              placeholder="Enter your Email"
              className="w-full p-2 mt-1 bg-[#2B2C2E] text-white border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-[#FF9900]"
              style={{
                backgroundColor: "#2B2C2E",

                color: "#D9D9D9",
                borderRadius: "0.375rem",
                outline: "none",
                border: "none",
                padding: "13px",
                marginBottom: "10px",
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {/* <div className="flex space-x-4">
            <div className="w-1/2">
              <label className="block font-normal text-xl mb-2" style={{ color: '#D9D9D9' }}>Select User Type</label>
              <select
                className="w-[392px] p-2"
                style={{ backgroundColor: '#344054', color: '#D9D9D9', borderRadius: '0.375rem', outline: 'none', border: 'none', padding: '13px', marginBottom: '10px' }}
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
                required
              >
                <option value="">Select User Type</option>
                <option value="User">User</option>
                <option value="Admin">Admin</option>
              </select>
            </div>
            <div className="w-1/2">
              <label className="block font-normal text-xl mb-2" style={{ color: '#D9D9D9' }}>Your Contact Number</label>
              <input
                type="text"
                placeholder="Enter your contact number"
                className="w-[392px] p-2"
                style={{ backgroundColor: '#344054', color: '#D9D9D9', borderRadius: '0.375rem', outline: 'none', border: 'none', padding: '13px', marginBottom: '10px' }}
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                required
              />
            </div>
          </div> */}
          <div>
            <label
              className="block font-normal text-xl mb-2"
              style={{ color: "#D9D9D9" }}
            >
              Your Password
            </label>
            <input
              type="password"
              placeholder="Enter your Password"
              className="w-full p-2 mt-1 bg-[#2B2C2E] text-white border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-[#FF9900]"
              style={{
                backgroundColor: "#2B2C2E",
                color: "#D9D9D9",
                borderRadius: "0.375rem",
                outline: "none",
                border: "none",
                padding: "13px",
                marginBottom: "10px",
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            <label
              className="block font-normal text-xl mb-2"
              style={{ color: "#D9D9D9" }}
            >
              Repeat Password
            </label>
            <input
              type="password"
              placeholder="Repeat your Password"
              className="w-full p-2 mt-1 bg-[#2B2C2E] text-white border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-[#FF9900]"
              style={{
                backgroundColor: "#2B2C2E",
                color: "#D9D9D9",
                borderRadius: "0.375rem",
                outline: "none",
                border: "none",
                padding: "13px",
                marginBottom: "10px",
              }}
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              className="h-4 w-4 focus:ring-yellow-500 border-gray-300 rounded"
              style={{ borderColor: "#FF9900", backgroundColor: "none" }}
              required
            />
            <label className="ml-2 text-base" style={{ color: "#D9D9D9" }}>
              I agree with the{" "}
              <a href="/terms" style={{ color: "#FF9900" }}>
                terms and conditions
              </a>
            </label>
          </div>
          <div className="text-lg">
            <button
              type="submit"
              className="w-full p-2 mt-4 bg-transparent border border-[#FF9900] rounded button--gpt text-white"
              disabled={loading}
            >
              {loading ? (
                <div className="flex justify-center ">
                  <div className="w-5 h-5 border-t-2 border-white rounded-full animate-spin"></div>
                </div>
              ) : (
                "Register New Account"
              )}
            </button>
            <button
              type="button"
              onClick={() => navigate("/login")}
              className="w-full py-2 mt-5 login-button button--secondary"
              // style={{
              //   color: "#FF9900",
              //   borderRadius: "0.375rem",
              //   outline: "none",
              //   border: "1px solid #FF9900",
              // }}
            >
              Back
            </button>
          </div>
        </form>
        {error && (
          <p className="text-center mt-4 text-base" style={{ color: "red" }}>
            {error}
          </p>
        )}
        <p className="text-center mt-4 text-base" style={{ color: "#D9D9D9" }}>
          Already have an account?{" "}
          <a href="#" style={{ color: "#FF9900" }}>
            <Link to="/login">Log In</Link>
          </a>
        </p>
        <br />
        <p className="text-center text-base mt-2" style={{ color: "#ffff" }}>
          *OpenAI ChatGPT subscription required.
        </p>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default SignUpPage;
