import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";

const LogoutPopup = ({ type, message, onClose, email }) => {
  const navigate = useNavigate();

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#171717] rounded-[12px] shadow-lg rounded-tl-lg w-[280px] sm:w-[540px] h-[274px] text-center relative">
        <div
          onClick={onClose}
          style={{
            position: "absolute",
            right: 10,
            padding: 10,
            cursor: "pointer",
          }}
        >
          <img src="/x-close.svg" />
        </div>
        <div className="w-[60px] mt-2 m-auto h-[60px] bg-[#FF9900] rounded-full border-[5px] border-gray-700 flex items-center justify-center">
          <div
            className={`text-3xl ${
              type === "success" ? "text-green-500" : "text-red-500"
            }`}
          >
            <img src={"/mdi_logout.svg"} alt="Success Icon" />
          </div>
        </div>
        <h2 className="mt-[20px] text-[20px] font-semibold mb-2 text-white">{`Log out`}</h2>
        <p className="text-gray-300 text-[16px] font-normal">{`Are you sure you want to log out`}</p>

        <div style={{ height: 1 }} className=" mt-10 w-full bg-[#373737]" />

        <div className="flex flex-row items-center mt-3 justify-center gap-2">
          <button
            onClick={() => onClose()}
            className="button--gpt w-[200px] px-4 py-2 text-white rounded-md mr-2 text-lg"
          >
            Cancel
          </button>
          <button
            onClick={() =>
              signOut(getAuth()).then(() => {
                onClose();
                navigate("/");
              })
            }
            className=" login-button button--secondary w-[200px] px-4 py-2 text-white rounded-md"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogoutPopup;
