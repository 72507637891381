import React from "react";
import { Link } from "react-router-dom";
import Popup from "../images/email-success.png";
import Popup1 from "../images/popup-icon (2).png";

const CustomPopup = ({
  type,
  message,
  onClose,
  headingText,
  buttonTextStr,
}) => {
  let heading = headingText ?? "Failed";
  let buttonText = buttonTextStr ?? "Try Again";
  let buttonDisabled = false;

  if (type === "success") {
    heading = "Login Successful";
    buttonText = "Explore RecruitPilot";
  } else if (type === "logout") {
    heading = "Logout Successful";
    buttonText = "Logged Out";
    buttonDisabled = true;
  } else if (type === "verif_success") {
    heading = "Email Verified";
    buttonText = "Explore RecruitPilot";
  } else if (type === "forgot_password") {
    heading = "Email sent";
    buttonText = "Go back";
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#171717] rounded-tl-lg w-[275px] sm:w-[493px] h-[274px] text-center relative">
        <div className="m-auto mt-5 left-[calc(50%-24px)] w-[55px] h-[55px] bg-gray-900 rounded-full border-[8px] border-gray-700 flex items-center justify-center">
          <div
            className={`text-3xl ${
              type === "success" ? "text-green-500" : "text-red-500"
            }`}
          >
            {(type === "success" || type === "forgot_password") && (
              <img src={Popup} alt="Success Icon" />
            )}
            {type === "error" && <img src={Popup1} alt="Error Icon" />}
          </div>
        </div>
        <h2 className="mt-[10px] text-[20px] font-semibold mb-2 text-white">
          {heading}
        </h2>
        <p className="text-gray-300 text-[16px] font-normal mb-4">{message}</p>
        <Link
          to={type === "success" ? "/try-gpt" : "#"}
          className={`block mt-6 sm:mt-14 w-auto sm:w-[445px] h-[44px] text-lg mx-4 sm:ml-7 bg-transparent border border-[#FF9900] text-[#FF9900] py-[12px] px-0 rounded-lg opacity-100 ${
            type === "success"
              ? "hover:bg-[#FF9900] hover:text-white"
              : "pointer-events-none"
          }`}
          onClick={onClose}
          style={{ pointerEvents: buttonDisabled ? "none" : "auto" }}
        >
          {buttonText}
        </Link>
      </div>
    </div>
  );
};

export default CustomPopup;
