import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { auth, db } from "../firebase/firebaseconfig";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import Swal from "sweetalert2";
import starIcon from "../images/star.png";
import checkIcon from "../images/Check icon.png";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { Zoom as Fade } from "react-awesome-reveal";
import Contact from "./Contact";
import bgContainer from "../images/recruitpilot-ai-bg.png";

const TryGptPage = () => {
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState("");
  const [showLogoutAlert, setShowLogoutAlert] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const cardDetails = [
    {
      title: "Feedback GPT",
      description:
        "Generate fast, personalised feedback at scale straight from a job description and a CV with comparative analysis and help put and end to ghosting.",
      points: [
        "Feedback personalisation",
        "Time management",
        "Enhanced candidate experience",
      ],
      buttonText: "Try GPT Now",
      link: "https://chatgpt.com/g/g-Kr0cN0oAC-feedback-gpt",
    },
    {
      title: "Job Ad GPT",
      description:
        "Condense lengthy job descriptions into clear, concise summaries or create job descriptions from scratch with just a few bulletpoints.",
      points: [
        "Improved Accessibility",
        "Bias Reduction",
        "Increased Consistency",
      ],
      buttonText: "Try GPT Now",
      link: "https://chatgpt.com/g/g-BmNIDFvau-job-ads-gpt",
    },
    {
      title: "CV Summary GPT",
      description:
        "Craft targeted, consistent candidate summaries, from a CV or profile to deliver objective and detailed insights for streamlined hiring decisions.",
      points: [
        "Objective Insights",
        "Dynamic Summarisation",
        "Consistent Presentation",
      ],
      buttonText: "Try GPT Now",
      link: "https://chatgpt.com/g/g-gH77WPUcQ-cv-summary-gpt",
    },
    {
      title: "Boolean Search GPT",
      description:
        "Generate precise Boolean search strings straight from job descriptions, enhancing recruiter efficiency and ensuring accurate candidate matches.",
      points: [
        "Efficient candidate sourcing",
        "Customised searches",
        "Targeted results",
      ],
      buttonText: "Try GPT Now",
      link: "https://chatgpt.com/g/g-3u1t0TQv2-boolean-builder-gpt",
    },
    {
      title: "Job ED&I GPT",
      description:
        "Optimize your job descriptions by identifying and eliminating biases, promoting inclusivity and attracting a more diverse and talented applicant pool.",
      points: [
        "Improved diversity and inclusion",
        "Mitigation of bias",
        "Higher quality applicants",
      ],
      buttonText: "Try GPT Now",
      link: "https://chatgpt.com/g/g-ONY3xCMjC-job-edi-gpt",
    },
  ];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUsername(userDoc.data().username);
        }
      } else {
        setUser(null);
        setUsername("");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (showLogoutAlert) {
      Swal.fire({
        icon: "success",
        title: "Logged out",
        text: "You have been logged out successfully.",
        timer: 2000,
        timerProgressBar: true,
      }).then(() => {
        navigate("/login");
      });
    }
  }, [showLogoutAlert, navigate]);

  return (
    <div className="w-full h-full flex flex-col items-center relative home-page-container">
      <Navbar />

      <div className="w-full mx-auto text-center">
        <div>
          <div className="mt-[180px] sm:mt-[100px] sm:mt-[200px]">
            <h1 className="text-4xl sm:text-6xl font-semibold">
              Welcome to <br />
              <span>RecruitPilot's GPTs</span>
            </h1>
            <p className="mt-20 font-semibold text-2xl sm:text-[32px]">
              <span>Our GPT features and benefits</span>
            </p>
            <p className="mt-2 p-5 text-sm sm:text-2xl font-normal font-[Urbanist]">
              Create job board ready ads and boolean search or summarise job
              descriptions and CV's in seconds.
            </p>
          </div>

          {cardDetails.map((card, index) => (
            <Fade>
              <div
                key={index}
                className="section--panel1 bg-[#1E1E1E] rounded-[30px] shadow-lg px-4 py-12 sm:p-6 mt-10 mx-auto sm:max-w-7xl text-center w-full"
              >
                <h3 className="text-2xl sm:text-4xl font-bold mb-4 flex items-center sm:justify-center text-left">
                  {card.title}{" "}
                  <img
                    src={starIcon}
                    alt="Star"
                    className="ml-2 h-[50px] w-[50px]"
                  />
                </h3>
                <p className="mb-5 sm:text-center text-left text-lg font-normal">
                  {card.description}
                </p>
                <ul className="list-none mb-5 flex sm:flex-row flex-col justify-start sm:justify-center sm:space-x-10">
                  {card.points.map((point, index) => (
                    <li key={index} className="flex flex-row mt-8 items-center">
                      <img
                        src={checkIcon}
                        alt="Check"
                        className="mr-2 sm:mr-2 h-6 w-6"
                      />
                      <p className="text-sm sm:text-lg font-normal text-left">
                        {point}
                      </p>
                    </li>
                  ))}
                </ul>
                <a
                  href={card.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button--gpt bg-[#ff9900] w-[190px] mt-8 text-white py-2 px-6 rounded flex items-center justify-center mx-auto"
                >
                  {card.buttonText}{" "}
                  <svg
                    className="w-6 h-6 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    ></path>
                  </svg>
                </a>
                {/* <p className="text-sm mt-8 text-gray-400">*OpenAI ChatGPT subscription required.</p> */}
              </div>
            </Fade>
          ))}

          <div className="py-24">
            <Contact isFooter={false} />
          </div>
        </div>

        <Footer />
      </div>

      <div className="absolute w-full h-[2100px] top-[200px] bg-logo"></div>
    </div>
  );
};

export default TryGptPage;
