import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebaseconfig";
import { sendEmailVerification } from "firebase/auth";

const VerifyPopup = ({
  type,
  headerText,
  messageText,
  showResetLink = false,
  triggerSend = false,
}) => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(showResetLink);
  const [secondsLeft, setSecondsLeft] = useState(30); // Set the initial time
  const [header, setHeader] = useState(headerText ?? "Email Verification");
  const [modalType, setType] = useState(type ?? null);
  const [message, setMessage] = useState(
    messageText ??
      "Please verify your email by clicking the link sent to your email address This page will automatically update once your email is verified."
  );
  const [resetLink, setResetLink] = useState(showResetLink ?? true);
  const [exploreLink, setExploreLink] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isButtonEnabled === false) {
      if (secondsLeft > 0) {
        const timer = setInterval(() => {
          setSecondsLeft((prevSeconds) => prevSeconds - 1);
        }, 1000); // Update every second

        return () => clearInterval(timer); // Cleanup the timer on component unmount
      } else {
        setIsButtonEnabled(true);
      }
    }
  }, [secondsLeft, isButtonEnabled]);

  useEffect(() => {
    handleAuthChanges();
  }, []);

  const handleAuthChanges = () => {
    const watchUserChanges = setInterval(async () => {
      console.log("debug => user: ", auth?.currentUser);
      const user = auth?.currentUser;
      await user.reload();
      if (user && user.emailVerified) {
        setHeader("Email Verified.");
        setMessage(
          "Congratulations. Your email is verified and you are now logged-in."
        );
        setResetLink(false);
        setExploreLink(true);
        clearInterval(watchUserChanges);
        setType("success");
      }
    }, 10000);
  };

  const handleSendVerification = useCallback(() => {
    const user = auth?.currentUser;
    if (user) {
      sendEmailVerification(user)
        .then(() => {
          handleAuthChanges();
        })
        .catch((error) => {
          console.error("Error sending verification email:", error);
        });
    }
    setIsButtonEnabled(false);
    setSecondsLeft(30);
  }, []);

  useEffect(() => {
    if (triggerSend) {
      handleSendVerification();
    }
  }, [triggerSend, handleSendVerification]);

  const resendLink = (
    <div>
      {
        <p className="text-gray-300 text-[16px] font-normal mb-4 mt-3">
          {!isButtonEnabled && (
            <>
              Resend link in <b>{secondsLeft} sec</b>
            </>
          )}
        </p>
      }
      <button
        className={`block w-full text-lg h-[44px] m-auto bg-transparent border border-[#FF9900] text-[#FF9900] py-[12px] px-0 rounded ${
          isButtonEnabled
            ? "hover:bg-[#FF9900] hover:text-white"
            : "opacity-50 cursor-not-allowed"
        }`}
        onClick={isButtonEnabled ? handleSendVerification : undefined}
        style={{
          border: isButtonEnabled ? "" : "1px solid #989898",
          color: isButtonEnabled ? "" : "#989898",
        }}
      >
        Resend Link
      </button>
    </div>
  );

  const exploreLinkButton = (
    <button
      className={`block w-full text-lg h-[44px] m-auto bg-transparent border border-[#FF9900] text-[#FF9900] py-[12px] px-0 rounded "hover:bg-[#FF9900] hover:text-white"`}
      onClick={() => navigate("/try-gpt")}
      style={{
        border: "1px solid #989898",
      }}
    >
      Explore RecruitPilot
    </button>
  );

  return (
    <div className="fixed  inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 rounded-[12px] shadow-lg rounded-tl-lg w-[280px] sm:w-[540px] text-center relative py-8 px-6">
        <div className="w-[48px] mt-2 m-auto h-[48px] bg-[#FF9900] rounded-full border-[8px] border-gray-700 flex items-center justify-center">
          <div className="text-3xl">
            {modalType === "success" ? (
              <img src={"/email-success.png"} alt="Success" />
            ) : (
              <img src={"/ic_outline-email.svg"} alt="Email Icon" />
            )}
          </div>
        </div>
        <h2 className="mt-[20px] text-[20px] font-semibold mb-2 text-white">
          {header}
        </h2>
        <p className="text-gray-300 text-[16px] font-normal pb-4">{message}</p>

        {resetLink && resendLink}
        {exploreLink && exploreLinkButton}
      </div>
    </div>
  );
};

export default VerifyPopup;
