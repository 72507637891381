import React from "react";
import heroimg from "../images/recruitement_revolution2.png";

const RecruitPilotTwo = () => {
  return (
    <div className="flex flex-col lg:flex-row mt-6 bg-[#1E1E1E] text-white items-center px-6 py-12 sm:p-12 rounded-lg mx-auto sm:rounded-[50px] gap-6">
      <div className="lg:w-2/3 text-[20px] flex flex-col gap-6">
        <p className="">
          RecruitPilot AI is a new, pioneering force in recruitment technology.
        </p>
        <p className="">
          With our sophisticated AI models and meticulously crafted algorithms,
          we ensure <br /> <span>unparalleled bias reduction</span> and set{" "}
          <span>new standards for application experience</span>
        </p>
        <p className="">
          Our RecTech platform ensures clients turbocharge their productivity
          and reduce time to hire, whilst eradicating the ghosting and biases
          that are stifling the recruitment industry in each and every job
          market the world over.
        </p>
      </div>
      <div className="flex-shrink-0 mb-6 lg:mb-0 lg:mr-8 lg:w-1/3">
        <img className="lg:ml-14" src={heroimg} />
      </div>
    </div>
  );
};

export default RecruitPilotTwo;
