import React from 'react';
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";


const animation = { duration: 40000, easing: (t) => t };


const MobileSlider = () => {
    const [sliderRef] = useKeenSlider({
        loop: true,
        renderMode: "performance",
        drag: true,
        slides: {
            perView: 2,
            spacing: 15,
        },
        created(s) {
            s.moveToIdx(5, true, animation);
        },
        updated(s) {
            s.moveToIdx(s.track.details?.abs + 5, true, animation);
        },
        animationEnded(s) {
            s.moveToIdx(s.track.details?.abs + 5, true, animation);
        },
    });

    return (
        <div className="relative overflow-hidden w-full mt-11">
            <div ref={sliderRef} className="keen-slider">
                {
                    [1, 2, 3, 4, 5, 6, 7].map((item, index) => (
                        <div className="keen-slider__slide">
                            <img
                                src={`/extension/ext-${index + 1}.svg`}
                                alt={`Slide ${index + 1}`}
                                // className="w-full m-3"

                            />
                        </div>
                    ))
                }

                {/* {[...Array(slideCount * 3)].map((slide, index) => (
                    <img src={`/extension/ext-${index % slideCount + 1}.svg`}
                        className="w-full m-3"
                        alt={`Slide ${index + 1}`}
                    />
                ))} */}
            </div>
        </div>

    );
};

export default MobileSlider;
